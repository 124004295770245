<template>
  <div class="bg end">
    <div class="class--con">
      <class-but @forward="forward"/>
    </div>
  </div>
</template>

<script>
import ClassBut from "@/views/conference/ConferenceItem/classBut.vue";
  export default {
    name: "twentyOnePage",
    components: {ClassBut},
    data(){
      return{
      }
    },
    methods:{
      forward(){
        this.$router.replace({
          name: 'ConferenceClassList'
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "reportClass.less";
  .end{
    background-image: url("~@/assets/image/conference/end.png");
  }
</style>
